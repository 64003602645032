<template>
  <div class="gg-container" v-loading="img == ''">
    <div class="nav-title">
      <i :class="['iconfont', icon]"></i>
      <p>{{ title }}</p>
    </div>
    <el-form
      ref="ruleForm"
      :model="form"
      label-width="130px"
      v-loading="loading"
      class="demo-ruleForm"
    >
      <div v-if="index == 1">
        <el-form-item label="平台标题">
          <el-input v-model="form.plat_title" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="网站logo">
          <customUpload
            :fileUrl="img"
            @on-success="(file) => onSuccess(file, 'site_logo')"
            width="100px"
            height="70px"
          ></customUpload>
        </el-form-item>
        <el-form-item label="SEO关键字">
          <el-input v-model="form.seo_keyword" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="SEO内容">
          <el-input v-model="form.seo_content" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="版权所有">
          <el-input v-model="form.copyright" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="备案号">
          <el-input v-model="form.registration" style="width: 60%"></el-input>
        </el-form-item>
      </div>
      <div v-else-if="index == 2">
        <el-form-item label="人脸识别" prop="name">
          <el-radio v-model="form.face_switch" label="on">开启</el-radio>
          <el-radio v-model="form.face_switch" label="off">关闭</el-radio>
        </el-form-item>
        <el-form-item label="诊疗自动上传监管" prop="diag_upload_switch">
          <el-radio v-model="form.diag_upload_switch" label="on">开启</el-radio>
          <el-radio v-model="form.diag_upload_switch" label="off"
            >关闭</el-radio
          >
        </el-form-item>
        <el-form-item label="处方流转开关" prop="pres_switch">
          <el-radio v-model="form.pres_switch" label="on">开启</el-radio>
          <el-radio v-model="form.pres_switch" label="off">关闭</el-radio>
        </el-form-item>
        <el-form-item label="医师病历签章" prop="esign_auto_doctor">
          <el-radio v-model="form.esign_doctor_emr" label="on"
            >开启静默签章</el-radio
          >
          <el-radio v-model="form.esign_doctor_emr" label="off"
            >关闭静默签章</el-radio
          >
        </el-form-item>
        <el-form-item label="医师处方签章" prop="esign_auto_doctor">
          <el-radio v-model="form.esign_auto_doctor" label="on"
            >开启静默签章</el-radio
          >
          <el-radio v-model="form.esign_auto_doctor" label="off"
            >关闭静默签章</el-radio
          >
        </el-form-item>
        <!-- <el-form-item label="初审签章" prop="esign_auto_phar">
					<el-radio v-model="form.esign_auto_phar" label="on">开启静默签章</el-radio>
					<el-radio v-model="form.esign_auto_phar" label="off">关闭静默签章</el-radio>
				</el-form-item> -->
        <el-form-item label="药师处方签章" prop="esign_auto_second_phar">
          <el-radio v-model="form.esign_auto_second_phar" label="on"
            >开启静默签章</el-radio
          >
          <el-radio v-model="form.esign_auto_second_phar" label="off"
            >关闭静默签章</el-radio
          >
        </el-form-item>
        <el-form-item label="默认药师ID" prop="phar_id">
          <el-input
            v-model="form.default_pharmacist"
            style="width: 60%"
          ></el-input>
        </el-form-item>
        <el-form-item label="默认医师ID">
          <el-input v-model="form.default_doctor" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="订单推送开关">
          <el-radio v-model="form.order_push_switch" label="on">开启</el-radio>
          <el-radio v-model="form.order_push_switch" label="off">关闭</el-radio>
        </el-form-item>

 <!--
       <el-form-item label="是否显示中药处方数量">
          <el-radio v-model="form.tc_pres_count_switch" label="on">是</el-radio>
          <el-radio v-model="form.tc_pres_count_switch" label="off"
            >否</el-radio
          >
        </el-form-item> 
-->

        <el-form-item label="服务费率">
          <el-input v-model="form.service" style="width: 60%"></el-input>
        </el-form-item>
      </div>
      <div v-else-if="index == 3">
        <h3>患者端小程序</h3>
        <el-form-item label="appid" prop="name">
          <el-input v-model="form.user_appid" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="appsecret" prop="diag_upload_switch">
          <el-input v-model="form.user_appsecret" style="width: 60%"></el-input>
        </el-form-item>
        <h3>医生端小程序</h3>
        <el-form-item label="appid" prop="name">
          <el-input v-model="form.doctor_appid" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="appsecret" prop="diag_upload_switch">
          <el-input
            v-model="form.doctor_appsecret"
            style="width: 60%"
          ></el-input>
        </el-form-item>
        <h3>医管家小程序</h3>
        <el-form-item label="appid" prop="name">
          <el-input v-model="form.bulter_appid" style="width: 60%"></el-input>
        </el-form-item>
        <el-form-item label="appsecret" prop="diag_upload_switch">
          <el-input
            v-model="form.bulter_appsecret"
            style="width: 60%"
          ></el-input>
        </el-form-item>
      </div>
      <div v-else-if="index == 4">
        <el-form-item prop="name" label="问诊费率">
          <el-input v-model="form.diag_rate" style="width: 300px"></el-input> %
        </el-form-item>
        <el-form-item label="其他补贴费率" prop="pres_rate">
          <el-input v-model="form.pres_rate" style="width: 300px"></el-input> %
        </el-form-item>
        <el-form-item label="药师审方费率" prop="pres_rate">
          <el-input
            v-model="form.examine_pres_rate"
            style="width: 300px"
          ></el-input>
          %
        </el-form-item>
        <el-form-item label="最低提现金额" prop="pres_rate">
          <el-input
            v-model="form.min_withdraw_amount"
            style="width: 300px"
          ></el-input>
        </el-form-item>
        <el-form-item label="每月最多提现次数" prop="pres_rate">
          <el-input
            v-model="form.max_withdraw_count"
            style="width: 300px"
          ></el-input>
        </el-form-item>
      </div>
      <div v-else-if="index == 12">
        <el-form-item label="用户知情同意书" prop="consent">
          <tinymce v-model="form.consent" :height="300" />
        </el-form-item>
        <el-form-item label="用户注册协议" prop="register_deal">
          <tinymce v-model="form.register_deal" :height="300" />
        </el-form-item>
        <el-form-item label="患者端隐私协议" prop="pat_conceal_deal">
          <tinymce v-model="form.pat_conceal_deal" :height="300" />
        </el-form-item>
        <el-form-item label="医生隐私协议" prop="conceal_deal">
          <tinymce v-model="form.conceal_deal" :height="300" />
        </el-form-item>
        <el-form-item label="医师多机构备案劳动协议" prop="work_deal">
          <tinymce v-model="form.work_deal" :height="300" />
        </el-form-item>
        <el-form-item label="电子签章调用委托授权书" prop="sign_grant">
          <tinymce v-model="form.sign_grant" :height="300" />
        </el-form-item>
        <el-form-item label="服务协议" prop="service_deal">
          <tinymce v-model="form.service_deal" :height="300" />
        </el-form-item>
        <el-form-item label="药师用户协议" prop="service_deal">
          <tinymce v-model="form.phar_deal" :height="300" />
        </el-form-item>
        <el-form-item label="药师隐私协议" prop="service_deal">
          <tinymce v-model="form.phar_privacy" :height="300" />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button type="success" size="small" @click="saveForm()"
        >保存</el-button
      >
    </span>
  </div>
</template>

<script>
import { saveConfig } from "@/api/basic/index";
import Tinymce from "@/components/Tinymce";
import customUpload from "@/components/customUpload";
export default {
  name: "platformConfig",
  components: {
    customUpload,
    Tinymce,
  },
  props: {
    index: {
      type: [Number, String],
    },
    title: [String],
    icon: [String],
    form: [Object],
  },
  mounted() {
    
  },
  created() {},
  data() {
    return {
      loading: false,
      statusTitle: "新增",
      datetimerange: "",
      searchParams: {},
      img: localStorage.getItem("img"),
      currInfo: {
        group_id: null,
        contraindication_name: "",
      },

      rules: {
        name: [
          {
            required: true,
            message: "请填写名称",
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            message: "请填写编码",
            trigger: "change",
          },
        ],
        cd_id: [
          {
            required: true,
            message: "请选择类型",
            trigger: "change",
          },
        ],
        sort: [
          {
            required: true,
            message: "请填写排序",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "change",
          },
        ],
      },
      tableData: [],
    };
  },
  methods: {
    onSuccess(file, name) {
      console.log(file, name);
      this.form[name] = file;
    },
    saveForm() {
      this.loading = true;
      this.form.type = this.index;
      saveConfig(this.form)
        .then((res) => {
          this.loading = false;
          if (res.code == 200) {
            localStorage.setItem("img", this.form.site_logo);
            return this.$message.success("保存成功");
          } else {
            return this.$message.error(res.message ? res.message : res.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    reset() {
			// this.searchParams = {};
      this.listQuery.page = 1;
				this.getList()
    },
  },
};
</script>

<style scoped lang="scss">
.dot {
  display: inline-block;
  border: 5px solid;
  border-radius: 50%;
  margin: 0 5px;
}

.nav-title {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 0 0 1px 40px;
}

.dialog-footer {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  width: 70%;
}

::v-deep.switch .el-switch__label {
  position: absolute;
  display: none;
  color: #fff !important;
}

/*打开时文字位置设置*/
::v-deep.switch .el-switch__label--right {
  z-index: 1;
}

/* 调整打开时文字的显示位子 */
::v-deep.switch .el-switch__label--right span {
  margin-left: -1px;
  // margin-right: 40px;
}

/*关闭时文字位置设置*/
::v-deep.switch .el-switch__label--left {
  z-index: 1;
}

/* 调整关闭时文字的显示位子 */
::v-deep.switch .el-switch__label--left span {
  margin-left: 22px;
}

/*显示文字*/
::v-deep.switch .el-switch__label.is-active {
  display: block;
}

/* 调整按钮的宽度 */
::v-deep.switch.el-switch .el-switch__core,
.el-switch .el-switch__label {
  width: 60px !important;
  margin: 0;
}
</style>
